.floatLabel {
  position: relative;
  margin-bottom: 12px;
}

.label {
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 17px;
  transition: 0.2s ease all;
}

.labelFloat {
  //top: 6px;
  font-size: 10px;
  top: -6px;
  padding: 0px 4px;
  background-color: white;

}
