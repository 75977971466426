@import "commons/Theme";

.gruppo, .gruppoFormato {
  width: 100%;
  min-height: 300px;
  margin-bottom: 24px;
  margin-top: 24px;
  padding: 15px 29px 18px 22px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
  background-color: white;


  .titleBox {
    h3{
      opacity: 0.75;
      font-size: 24px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.42px;
      color: #000000;
      text-align: left;
      font-weight: bold;

      span{
        margin-right: 16px;
      }
    }
  }
  .titleRicetta{
    background-color: rgba(230, 221, 203, 0.5);
    height:56px;
    margin-bottom: 10px;
  }
  .totale{
    background-color: rgba(52, 199, 89, 0.15);
    height:56px;
    font-size:36px;
    font-weight: 700;
    text-align: center;
    span{
      font-size: 12px;
      font-weight: 400;
      line-height: 56px;
    }
  }
  .margine{
    background-color: #e5e1d9;
    background-color: rgba(40, 132, 63, 0.15);
    height:56px;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    line-height: 56px;
    span{
      font-size: 12px;
      font-weight: 400;
      line-height: 56px;
    }

  }

  .btnAddRicetta{
    //width: 108px;
    height: 36px;
    //margin: 0 0 0 1002px;
    padding: 7px 15px 7px 17px;
    border-radius: 5px;
    border: solid 1px #000000;
    background-color: $col-btn;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: center;
    color: #000000;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  .btnAddRicetta:hover{
    border: solid 1px $col-caselle;
    background-color: $col-btn-hover;
    color: #fff;
  }
}
.gruppoFormato {
  min-height: 170px;
}
.descrizione{
  border-radius: 6px;
}
.foglia{
  pointer-events: none;
}

.offertaHeader{
  min-height: $header-h;
  width:100%;
  background-color: $col-header;

  //box-shadow: 0 3px 10px rgba(0,0,0,.15);
  position: sticky;
  top:$header-h;
  z-index: 400;

  > div {
    margin:auto;
    width:$tot-w;
    max-width: $max-w;
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  a{
    color:black;
    font-size: 30px !important;
    line-height: 30px;
    display: inline;
    margin-right: 24px;
  }
  h2 {
    font-size: 24px !important;
    line-height: 24px;
    font-weight: 900;
    display: inline;
  }
}
.btnSalva{
  min-width: 108px;
  height: 36px;
  //margin: 0 0 0 1002px;
  padding: 7px 15px 7px 17px;
  border-radius: 5px;
  border: solid 1px #000000;
  background-color: $col-btn;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: center;
  color: #000000;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.btnSalva:hover{
  border: solid 1px $col-caselle;
  background-color: $col-btn-hover;
  color: #fff;
}
.totaleCollapse{
  font-size: 13px;
  padding: 8px 24px;
  border-radius: 6px;
  height: 60px;
  background-color: #fafafa;
  margin-right: 24px;
  b{
    font-weight: 900;
    font-size: 16px;
  }

}
.deleteCollapse{
  transition: color .2s ease-out;
}
.deleteCollapse:hover{
  //opacity: .3;
  color:red;
}


.selectBlocked{
  > div {
    pointer-events: none;
  }
  cursor: no-drop;

  &:hover {
    > div{
      border: 1px solid #d9d9d9 !important;
    }
  }

}
