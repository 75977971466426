@import '~antd/dist/antd.css';
//@import 'components/lista-offerte/ListaOfferte.scss';
@import './commons/Theme';
//@import './AppTheme.less'; // variables to override above


.App {
  text-align: center;
  background-color: $body-bg;
  height: 100%;
  min-height: calc(100vh - 56px);

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p{
  .stampa{
    color: $col-text !important;
  }
}
.rigaTest{
  background-color: bisque;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $header-bg;
  height: $header-h;
  font-size: calc(10px + 2vmin);
  color: black;
  position: sticky;
  top: 0;
  z-index: 400;

  .header-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: $max-w;
    width: $tot-w;
    margin: auto;
    padding: 0 25px;

    .header-logo {
      height: 37px;
    }
  }
}

.App-link {
  color: #61dafb;
}

.App-page {
  max-width: $max-w;
  width: $tot-w;
  min-width: $min-w;
  //margin: 24px 27px 0 22px;
  //padding: 24px 22px 16px 25px;
  margin: auto;
  padding: 0;
  border-radius: 5px;
  background-color: white;
  height: 100%;
  min-height: 100%;

  button{
    outline: none;
  }
}

.offerta-page {
  background-color: unset;
  max-width: $max-w-page;
  padding-bottom: 10px;
  z-index: 10;
  position: relative;
}
.ricettaLoader{
  position: absolute;
  top: 20px;
  bottom: 0;
  margin: auto;
  width: 100%;
}
.loadingRecord{
  opacity: .6;
  pointer-events: none;
}
.loadingOfferta{
  position: absolute;
  top: 42%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  span{
    padding: 0 4px
  }
}
.loadingOffertaHidden{
  opacity: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 100vh;

  .tabella-temp{
    min-width: 572px;
    width: 70%;
    height: 483px;
    margin: auto;
    position: relative;
    display: block;

  }
  .form {
    min-width: 572px;
    width: 30%;
    height: 483px;
    margin: auto;
    background-color: white;
    padding: 40px;
    padding-top: 126px;
    background-position: top 37px center;
    background-repeat: no-repeat;
    background-size: 150px auto;
  }

  .login-form-button {
    //width: 100%;
    width: 128px;
    margin: auto;
    display: block;
  }
}

.container_login {
  //background-image: 'url(' + require('./assets/bgLogin.jpg') + ')';
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  flex-direction: column;

  .robacancella {
    position: fixed;
    top: 10px;
    left: 10px;
  }
}

.example {
  margin: 12px;
}

body {
  .ant-spin-dot-item {
    background-color: $col-text;
  }
  .ant-table-tbody > tr > td {
    //word-wrap: break-word;
    //word-break: break-all;
    max-width: 220px;
  }
  .ant-table-tbody > tr > td:nth-child(2){
    min-width: 96px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: $col-btn-hover;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    //position: absolute;
    //top: 0;
    //right: 0;
    //bottom: 0;
    //left: 0;
    //z-index: 1;
    border: 1px solid $col-btn-hover;
    //border-radius: 2px;
    //content: '';
  }

  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused ,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: rgba(220, 212, 197, 1);
    //border-right-width: 1px !important;
    //z-index: 1;
    //outline: 0;
    -webkit-box-shadow: 0 0 0 2px $col-btn;
    box-shadow: 0 0 0 2px $col-btn;
  }

  .ant-modal-confirm-title{
    h3 {
      font-weight: 900;
      font-size: 24px;
    }
  }
  .ant-modal-confirm-body {
    svg {
      height: 35px;
    }
  }
  .ant-modal-confirm-btns button {
    color: #999999 !important;
    background-color: $col-btn !important;
    border-color: $col-btn !important;
    height: 50px;
    padding: 11px 40px;
  }
  .ant-modal-confirm-btns button:hover {
     color: white !important;
     background-color: $col-btn-hover !important;
     border-color: $col-btn !important;
   }

  .ant-modal-confirm-btns  .ant-btn-primary {
    color: white !important;
    background-color: $col-btn-hover !important;
    border-color: $col-btn !important;
  }

  .ant-modal-confirm-btns  .ant-btn-primary:hover {
    color: $col-text !important;
    background-color: $col-btn !important;
    border-color: $col-btn !important;
  }

  .login-form-button, .ant-btn-primary {
    color: #999999;
    background-color: $col-btn;
    border-color: $col-btn;
  }

  .ant-btn-primary:hover {
    color: white;
    background-color: $col-btn-hover;
    border-color: $col-btn;
  }
  .ant-btn-link {
    color: $col-caselle;
  }
  .ant-btn-link:hover, .ant-btn-link:focus {
    color: $col-caselle-hover;
  }
  .login-form-button:disabled, .ant-btn-primary:disabled {
    color: white;
    background-color: $col-btn-disabled;
  }

  .ant-input-number:hover ,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $col-btn-hover;
    border-right-width: 1px !important;
  }

  /* Force update ant style */
  .ant-input {
    padding: 11px 12px 4px 11px;
  }

  .ant-input-number ,
  .ant-select .ant-select-selector {
    padding: 11px 10px 4px 11px;
    text-align: left;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-input-number ,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 11px 10px 4px 11px;
    height: 56px;
    border-radius: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    top: 11px;
  }
  .ant-input-number:focus, .ant-input-number-focused ,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-custumize-input) .ant-select-selector {

    border-color: $col-btn-hover;
    -webkit-box-shadow: 0 0 0 2px $col-btn;
    box-shadow: 0 0 0 2px $col-btn;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    //color: $col-table-select;
    //font-weight: 600;
    background-color: $col-table-select;
  }

  .ant-picker-range:hover,.ant-input:hover {
    border-color: $col-btn-hover;
    //border-right-width: 1px !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-picker-range, .ant-input-affix-wrapper {
    padding: 11px 10px 4px 11px;
    height: 56px;
    border-radius: 6px;
    text-align: center;
  }
   .ant-pagination-options .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    //padding: 11px 10px 4px 11px;
    //height: 56px;
    //border-radius: 6px;
    padding: 1px 10px 2px 10px;
    height: 32px;
    border-radius: 2px;
  }

  .ant-picker-range .ant-picker-active-bar {
    //bottom: -1px;
    //height: 2px;
    //margin-left: 11px;
    background: $col-btn-hover;
  }
  .ant-picker-focused {
    border-color: $col-btn-hover;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-table-thead > tr > th {
    //color: rgba(0, 0, 0, 0.85);
    //font-weight: 500;
    //text-align: left;
    //background: #fafafa;
    //border-bottom: 1px solid #f0f0f0;
    //-webkit-transition: background 0.2s ease;
    //transition: background 0.2s ease;
    font-weight: 900;
  }
  .ant-table-filter-trigger {
    col: $col-btn;
  }
  .ant-table-filter-trigger.active {
    color: $col-btn-hover;
  }

  .ant-collapse {
    text-align: left;

    .ant-collapse-header {
      font-weight: 700;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    font-size: 16px;
    line-height: 27px;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 40px;
  }
  .ant-collapse .ant-collapse-item-disabled .ant-collapse-header .ant-collapse-extra {
    display: none;
  }
  .ant-collapse .ant-collapse-item-disabled .ant-collapse-header .ant-collapse-arrow {
    display: none;
    left:16px;
    right:auto;
  }

  .site-collapse-custom-collapse {
    font-size: 20px;
    background-color: rgba(230, 221, 203, 0.5);
    border: 1px solid white;
    margin-bottom: 30px;
  }

  .ant-dropdown-menu {
    min-width: 185px;
    border-radius: 6px;
    padding: 0;
  }

  .ant-dropdown .ant-dropdown-menu li {
    border-bottom: 1px solid #f3f3f3;

    button, p {
      border: 0;
      background-color: unset;
      height: 30px;
      line-height: 30px;
      padding: 3px 0;
      box-sizing: content-box;

      span {
        margin-right: 10px;
        //max-width: 30px;
        vertical-align: middle;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    color: $col-btn-hover;
    //background-color: #e6f7ff;
    background-color: $col-caselle-active;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: $col-caselle !important;
  }
  .ant-radio-inner::after {
    background-color: $col-caselle;
  }
  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: $col-caselle-hover;
  }
  /////////////////////////////////////

  .ant-pagination-item-active a {
    color: $col-caselle;
  }

  .ant-pagination-item-active {
    //font-weight: 500;
    //background: #fff;
    border-color: $col-caselle;
  }

  .ant-pagination-item-active:focus,  .ant-pagination-item-active:hover {
    border-color: $col-caselle-hover;
    a{
      color: $col-caselle-hover;

    }
  }
  .ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: $col-caselle-hover;
    a{
      color: $col-caselle-hover;
    }
  }
  .ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: $col-caselle-hover;
    border-color: $col-caselle-hover;
  }
  .ant-divider-horizontal.ant-divider-with-text {
    margin-top: 4px;
  }

}

.ricetteIncluse{
  pointer-events: none;
  span{
    font-size: 11px;
    font-weight: 600;
    color: #5c5c5c;
  }
  .costi:last-child{
    margin-bottom: 0;
    .ant-divider-with-text{
      margin-bottom: 2px;
    }
  }
}



