// questo serve per avere la conversione tra pixel e vw
// per avere la conversione su mobile >>> font-size 2.13vw equivale a 8px su iPhone X

$size-1: 0.27vw;
$size-2: 0.53vw;
$size-3: 0.8vw;
$size-5: 1.33vw;
$size-6: 1.6vw;
$size-8: 2.13vw;
$size-9: 2.4vw;
$size-10: 2.67vw;
$size-11: 2.93vw;
$size-12: 3.2vw;
$size-13: 3.47vw;
$size-14: 3.73vw;
$size-15: 4vw;
$size-16: 4.27vw;
$size-17: 4.53vw;
$size-18: 4.8vw;
$size-20: 5.33vw;
$size-22: 5.87vw;
$size-23: 6.21vw;
$size-24: 6.4vw;
$size-25: 6.67vw;
$size-26: 6.93vw;
$size-28: 7.47vw;
$size-30: 8vw;
$size-32: 8.53vw;
$size-34: 9.05vw;
$size-36: 9.6vw;
$size-40: 10.67vw;
$size-46: 12.24vw;
$size-50: 13.3vw;
$size-60: 16vw;
$size-70: 18.67vw;


$w-1220:1220px;
$w-1120:1120px;


$tot-w:calc(100% - 48px);
$max-w:1980px;
$max-w-page:1391px;
$min-w:100px;

$header-h: 56px;
