@import "commons/Theme";


.btnElimina{
  min-width: 108px;
  height: 36px;
  //margin: 0 0 0 1002px;
  //padding: 7px 15px 7px 17px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  border: transparent 0px ;
  margin: 4px 4px;
  opacity: 1;
  transition: opacity .2s ease-in-out;

}
.btnElimina:hover{
  //background-color: $col-btn-hover;
  opacity: .4;
}
