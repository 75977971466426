// Body
$body-bg: #ececea;
$col-text: #4c4c4c;
$header-bg: #f7f7f7;
$col-text-hover: #7d7d7d;

$col-btn: rgba(120, 120, 128, 0.2);
$col-btn-hover: #98875f;
$col-btn-disabled: #666;
$col-caselle: #98875f;
$col-caselle-hover: #d4c399;
$col-caselle-active: #d0cbc4;

$col-table-light: #fffff;
$col-table-dark: #f7f7f7;//rgba(94, 94, 94, 0.05);
$col-table-border: #cecece;
$col-table-select: #f7f1e4;

$col-header: #e6ddcb;




//$col-viola: #48488b;
//$col-verde: #8AC141;
//$col-blu: #008bc0;
$col-error: #f5222d;
//
////$linear-grad-viola: linear-gradient(171.4deg, $col-rosa 0.55%, $col-viola 75.94%);
////$linear-grad-verdeBlu: linear-gradient(171.23deg, $col-verde 0.55%, $col-blu 75.94%);
////$linear-grad-verdeBlu-vert: linear-gradient(180deg, $col-verde, $col-blu);
//
//// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$algae-green: #25d366;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;
//$grey: #B7B7B7;
//$white: #FFFFFF;

