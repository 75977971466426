@import "commons/Theme";

.testo {
  cursor: no-drop;
  line-height: 52px;

  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  height: 56px;
  border-radius: 6px;
}
.left{
  text-align: left;

}
