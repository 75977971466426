/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/open-sans-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/open-sans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/open-sans-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/open-sans-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/open-sans-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/open-sans-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/open-sans-700.eot'); /* IE9 Compat Modes */
  src: local(''),
          url('./fonts/open-sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('./fonts/open-sans-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('./fonts/open-sans-700.woff') format('woff'), /* Modern Browsers */
          url('./fonts/open-sans-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('./fonts/open-sans-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root{
  min-height: 100%;
  /*height: 100vh;*/
  /*width: 100vw;*/
}


body .ant-btn {
  /*margin: 8px;*/
}


