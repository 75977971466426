//@import "./variables/index";
@import "./variables/colors";
@import "./variables/themes";
@import "./variables/sizes";

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

body{
  font-family: 'Open Sans', sans-serif;

  .ant-table-cell{
    border-bottom-color: $col-table-border !important;
  }
  .table-row-light {
    background-color: $col-table-light ;
  }
  .table-row-dark {
    background-color: $col-table-dark;
  }

  .table-row-light td {
    background-color: $col-table-light ;
    /*font-size: 12px;*/
  }

  .table-row-dark td {
    /*font-size: 12px;*/
    background-color: $col-table-dark;
  }

  .ant-table-tbody > tr.ant-table-row {
    cursor: pointer;
  }




  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: $col-table-select;
  }

  .ant-table-tbody > tr.ant-table-row > td > .ant-btn {
    border-radius: 30px ;
    border-style: unset ;
    background: rgba(216, 216, 216, 0);
  }
  .ant-table-tbody > tr.ant-table-row > td > .ant-btn:hover {
    background: rgba(216, 216, 216, 0.46) ;
  }
}
