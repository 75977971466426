@import './commons/Theme';

.fullPageMessage{
  height: calc(100vh - 268px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;

  .mess{
    color: $col-text;
    line-height: 20px;
  }
  h5{
    line-height: 24px;
  }
}
