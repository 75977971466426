@import "commons/Theme";

.offerteHeaderBox {

  display:flex;
  flex-direction:row;
  align-items:flex-start;
  justify-content:space-between;

  margin:auto;
  margin-top: 24px;
  padding: 25px;
  //a {
  //  color: $col-text;
  //  text-decoration: none;
  //  opacity: 1;
  //  transition: opacity .1s ease-out;
  //}
  //
  //a:hover{
  //  color: $col-text-hover;
  //  opacity: .3;
  //}
  h2{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    color: #000000;
    opacity: .75;
  }

  .btnAdd{
    //width: 108px;
    height: 36px;
    //margin: 0 0 0 1002px;
    padding: 7px 15px 7px 17px;
    border-radius: 5px;
    border: solid 1px #000000;
    background-color: $col-btn;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.28px;
    text-align: center;
    color: #000000;
    cursor: pointer;
    transition: all .2s ease-out;
  }

  .btnAdd:hover{
    border: solid 1px $col-caselle;
    background-color: $col-btn-hover;
    color: #fff;
  }
}
.offerteHeaderFiltri {

  margin:auto;
  padding: 25px;
}
